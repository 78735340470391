<template>
<div>
  <Header/>

  <slot></slot>
</div>
</template>

<script>
import Header from "../components/Layout/Header";
export default {
  name: "empty",
  components: {Header}
}
</script>

<style scoped>

</style>
